<template>
  <div>
    <div class="flix-container-fluid flix-html-html" style=" margin-top: 20px; background-color:#ddd; color: #000;">
      <div class="flix-container" style="padding: 50px 0;">
        <div class="flix-form-group" v-if="data" v-html="getText()">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object
  },
  data () {
    return {

    }
  },
  methods: {
    getText () {
      var link = this.$flix_url + this.$router.resolve({ name: 'privateEvent', params: { id: this.data.ID } }).href
      link = '<a href="' + link + '" class="flix-html-a flix-text-danger">' + link + '</a>'

      var editLink = this.$flix_url + this.$router.resolve({ name: 'privateEventEdit', params: { id: this.data.ID } }).href
      editLink = '<a href="' + editLink + '" class="flix-html-a flix-text-danger">' + editLink + '</a>'
      var txt = this.$nl2br(this.$t('email.privateEvent.pageText'))
        .split('{email}').join(this.data.email)
        .split('{link}').join(link)
        .split('{editLink}').join(editLink)
      return txt
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-form-group
    word-wrap: break-word
</style>
